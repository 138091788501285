import { User } from './user.model';

export class UserAccess {
   isUserLogggedIn: boolean;
   //currentLoggedInUser: User;
   isSupportAdmin:boolean;

   //User
   canViewUsersSection: boolean;
   canCreateUser: boolean;
   canEditUser: boolean;
   canDeleteUser: boolean;
   

   //Licensors
   canViewLicensorsSection: boolean;
   canCreateLicensor: boolean;
   canEditLicensor: boolean;
   canDeleteLicensor: boolean;
   

   //Brand
   canViewBrandsSection: boolean;
   canCreateBrand: boolean;
   canEditBrand: boolean;
   canDeleteBrand: boolean;
   canUploadBrandPnls: boolean;
   canDeleteBrandPnls: boolean;
   canDefaultPnl:boolean;

   //Product
   canViewProductsSection: boolean;
   canCreateProduct: boolean;
   canEditProduct: boolean;
   canDeleteProduct: boolean;
   canViewArtTracker:boolean;
   canEditArtTracker:boolean;
   canUploadVisuals: boolean;
   canDownloadVisuals: boolean;
   canDeleteVisuals: boolean;

   canUploadMasterArts: boolean;
   canDownloadMasterArts: boolean;
   canDeleteMasterArts: boolean;

   canUploadPngFront: boolean;
   canDownloadPngFront: boolean;
   canDeletePngFront: boolean;

   canUploadPngBack: boolean;
   canDownloadPngBack: boolean;
   canDeletePngBack: boolean;

   canUploadPhotoApproval: boolean;
   canDownloadPhotoApproval: boolean;
   canDeletePhotoApproval: boolean;

   canUploadPrinterFiles: boolean;
   canDownloadPrinterFiles: boolean;
   canDeletePrinterFiles: boolean;

   canDownloadPnls: boolean
   canPreferPnl:boolean;
   canViewProductSimilarDesigns: boolean;
   canCreateCatalog: boolean;

   canChangeProductPriority:boolean;


   canEditAdhocProduct: boolean;
   canDeleteAdhocProduct: boolean;

   canCloneProductAdvanced: boolean;

   //Metadata
   canViewMetadata: boolean;
   canCreateMetadata: boolean;
   canEditMetadata: boolean;
   canDeleteMetadata: boolean;

   // Product Metadata
   canViewProductMetadata: boolean;
   canCreateProductMetadata: boolean;
   canEditProductMetadata: boolean;
   canDeleteProductMetadata: boolean;

   // UPC
   canViewUpc: boolean;
   canCreateUpc: boolean;
   //canEditUpc: boolean;
   //canDeleteUpc: boolean;

   //Brand Rights Overview
   canViewBrandRights: boolean;
   canCreateBrandRights: boolean;
   canEditBrandRights: boolean;
   canDeleteBrandRights: boolean;

   //Licensor Royalty Overview
   canViewRoyalty: boolean;
   canCreateRoyalty: boolean;
   canEditRoyalty: boolean;
   canDeleteRoyalty: boolean;

   //PDF Forms
   canViewPdfForms:boolean;
   canEditPdfForms:boolean;
   canDeletePdfForms:boolean;
   canCreatePdfForms:boolean;

   //SKU Tracker
   canViewSkuTracker:boolean;
   canEditSkuTracker:boolean;

   canCreateSubmissionSheet:boolean;
   canCreateApprovalForms:boolean;
   canCreatePrinterMatrix: boolean;
   canCreatePhotoApproval:boolean;

   canViewProductApprovalSection:boolean;
   canUploadApprovalDocs:boolean;
   canDownloadApprovalDocs:boolean;
   canDeleteApprovalDocs:boolean;

   canViewSkuDashbaord:boolean;

   canViewCustomers:boolean;
   canCreateCustomers:boolean;
   canEditCustomers:boolean;
   canDeleteCustomers:boolean;

   canViewLicensorSampleReport:boolean;
   canViewUpcReport:boolean;

   canUploadLicensorFiles:boolean;
   canDownloadLicensorFiles:boolean;
   canDeleteLicensorFiles:boolean;

   canViewLicensorContractFiles:boolean;
   canUploadLicensorContractFiles:boolean;
   canDownloadLicensorContractFiles:boolean;
   canDeleteLicensorContractFiles:boolean;

   canViewPreProTracker:boolean;
   canEditPreProTracker:boolean;

   canUploadBrandFiles:boolean;
   canDownloadBrandFiles:boolean;
   canDeleteBrandFiles:boolean;

   canUploadBrandTrendFiles:boolean;
   canDeleteBrandTrendFiles:boolean;

   inactiveOverRideProductSelection:boolean;
   exclusiveOverRideProductSelection:boolean;
   canDeleteSkuTrackerItems:boolean;

   canViewBlanks:boolean;
   canCreateBlanks:boolean;
   canEditBlanks:boolean;
   canDeleteBlanks:boolean;

   canViewSalesOrder:boolean;
   canViewAllSalesOrder:boolean;
   canCreateSalesOrder:boolean;
   canEditSalesOrder:boolean;
   canCloneSalesOrder:boolean;
   canDeleteSalesOrder:boolean;
   canSubmitSalesOrder:boolean;
   canUnsubmitSalesOrder:boolean;
   canExportSalesOrder:boolean;
   canExportSalesOrderRef:boolean;
   canExportSalesOrderHistory:boolean;
   canCreateCatalogForSalesOrder:boolean;
   canReassignSalesOrder:boolean;
   canChangeProductionStatus:boolean;
   canOverrideProductionStatus:boolean;
   canRequestForReCosting:boolean;
   canUploadCustomerPO:boolean;
   canDeleteCustomerPO:boolean;
   canDownloadCustomerPO:boolean;
   canRenameCustomerPO:boolean;
   canSalesForAllCustomers:boolean;

   canUploadSalesFiles:boolean;
   canDeleteSalesFiles:boolean;
   canDownloadSalesFiles:boolean;
   canRenameSalesFiles:boolean;
   canQuickViewSalesFiles:boolean;

   canViewProductionTracker:boolean;
   canEditProductionTracker:boolean;
   canViewProductionTrackerList:boolean;

   canViewCostingSection:boolean;
   canViewCostingAllocation:boolean;
   canEditCostingAllocation:boolean;
   canSubmitCostingAllocation:boolean;
   canSaveDraftCostingAllocation:boolean;
   canViewCostingSummary:boolean;
   canApproveCosting:boolean;
   canViewRepeatOrderDetails:boolean;
   canViewBlankSummary:boolean;
   canViewPrinterSummary:boolean;

   canViewEstimationSection:boolean;
   canViewEstimationCosting:boolean;
   canViewEstimationCostingSummary:boolean;
   canEditEstimationCostingAllocation:boolean;
   canSubmitEstimationCostingAllocation:boolean;
   canAcknowledgeEstimation:boolean;

   canViewSalesDashbaord:boolean;

   canViewVendors:boolean;
   canCreateVendors:boolean;
   canEditVendors:boolean;
   canDeleteVendors:boolean;

   canViewPrinterCapacity:boolean;
   canDownloadPrinterCapacity:boolean;
   

   canViewProductionCoordinatorCustomerAssignment:boolean

   canViewPrinterPortalList:boolean;

   canViewFileClosureSummary:boolean;
   canDownloadFileClosureSummary:boolean;

   canViewRoyaltyMismatchList:boolean;
   canDownloadRoyaltyMismatch:boolean;

   canViewTaskManagement:boolean;

   //Accounts
   canViewChargebackSummary: boolean;
   canExportChargeBackSummary: boolean;
   canMaintainChargeBack: boolean;
   canDeleteChargeBack: boolean;
   canMaintainChargeBackFiles: boolean;
   canDownloadChargeBackFiles: boolean;
   canViewChargeBackFiles: boolean;

   //sage
   canCreateItemSageAdhoc:boolean;
   canRetryCreateItemSage:boolean;
   canEditPurchaseOrder:boolean;
   canRetryPurchaseOrderPdf:boolean;


   // WMS
   canViewWmsReports:boolean;
   canViewWmsDashboard:boolean;
  

}
