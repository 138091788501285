import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OrderDetail, OrderDetailSizeQuantity } from '../../model/sales/order-detail.model';
import { SalesOrder } from '../../model/sales/sales-order.model';
import { SizeOrderDetail } from '../../model/sales/size-order-detail.model';
import { SubOrderDetail } from '../../model/sales/sub-order-detail.model';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { MetadataService } from '../metadata.service';
import { BlankSummary, BlankSummaryDetail, BlankSummaryView } from '../../model/sales/costing/allocation/summary/blank-summary.model';
import { SharedService } from '../shared.service';
import { PrinterSummary, PrinterSummaryDetail, PrinterSummaryView } from '../../model/sales/costing/allocation/summary/printer-summary.model';
import { SalesService } from './sales.service';
import { Product } from '../../model/product.model';
import { BrandService } from '../brand.service';
import { Brand } from '../../model/brand.model';
import { PrinterBlanksDistributionGrouped } from '../../model/sales/costing/allocation/printer-size-details.model';
import { MetadataSize } from '../../model/product-metadata/metadata-size.model';


@Injectable({
   providedIn: 'root'
})

export class SalesHelperService {
  
   //blanksColourListFromMetadataSubject: BehaviorSubject<string[]>;
   blanksQualityListFromMetadataSubject: BehaviorSubject<string[]>;
   packagingTypeListFromMetadataSubject: BehaviorSubject<string[]>;
   customerPoTypeListFromMetadataSubject: BehaviorSubject<string[]>;
   trimsListFromMetadataSubject: BehaviorSubject<string[]>;
   //sizesListFromMetadataSubject: BehaviorSubject<string[]>;
   cooListFromMetadataSubject: BehaviorSubject<string[]>;
   printerJobTypeFromMetadataSubject: BehaviorSubject<string[]>;
   skuStatusesNotAllowedForPreProRequiredFromMetadataSubject: BehaviorSubject<string[]>;


   //public blanksColourListFromMetadata: Observable<string[]>;
   public blanksQualityListFromMetadata: Observable<string[]>;
   public packagingTypeListFromMetadata: Observable<string[]>;
   public customerPoTypeListFromMetadata: Observable<string[]>;
   public trimsListFromMetadata: Observable<string[]>;
   //public sizesListFromMetadata: Observable<string[]>;
   public cooListFromMetadata: Observable<string[]>;
   public printerJobTypeFromMetadata: Observable<string[]>;
   public skuStatusesNotAllowedForPreProRequiredFromMetadata: Observable<string[]>;


   productionStatusForCostingApproved='COSTING_APPROVAL_COMPLETE';
   productionStatusForCostingPendingApproval='COSTING_APPROVAL_PENDING';
   productionStatusForCostingPending='COSTING_GENERATION_PENDING';
   productionStatusForEstimationAcknowledgment='ESTIMATIONS_ACKNOWLEDGED';
   productionStatusList:any[]=[
      {key:'REVIEW_SUBMISSION',disable:false},
      {key:'REVIEW_RESUBMISSION',disable:false},
      {key:'COSTING_GENERATION_PENDING',disable:false},
      {key:'COSTING_APPROVAL_PENDING',disable:true},
      {key:'COSTING_APPROVAL_COMPLETE',disable:true},
      {key:'PO_PLACEMENT_PENDING',disable:false},
      {key:'FULFILLMENT_IN_PROGRESS',disable:false},
      {key:'SHIPPED',disable:false},
      {key:'PARTIALLY_SHIPPED',disable:false},
      {key:'INVOICED',disable:false},
      {key:'PARTIALLY_INVOICED',disable:false},
      {key:'FILE_CLOSED',disable:false},
      {key:'CANCELLED',disable:false}
      ];
   productionStatusListForFilter=this.productionStatusList.concat( {key:'STATUS_UPDATE_REQUIRED',disable:false});
   inProgressProductionStatusList:string[]=['REVIEW_SUBMISSION',
   'REVIEW_RESUBMISSION',
   'COSTING_GENERATION_PENDING',
   'COSTING_APPROVAL_PENDING',
   'COSTING_APPROVAL_COMPLETE',
   'PO_PLACEMENT_PENDING',
   'FULFILLMENT_IN_PROGRESS',
   'PARTIALLY_SHIPPED',
   'STATUS_UPDATE_REQUIRED'];

// Change in maintenance controller and Scheduler required if this list chnages
   productionStatusListFilterForProductionTracker=[
      {value:'REVIEW_SUBMISSION',display:'Review Submission',selected:true},
      {value:'REVIEW_RESUBMISSION',display:'Review Resubmission',selected:true},
      {value:'COSTING_GENERATION_PENDING',display:'Costing Pending',selected:true},
      {value:'COSTING_APPROVAL_PENDING',display:'Costing Approval Pending',selected:true},
      {value:'COSTING_APPROVAL_COMPLETE',display:'Costing Approval Complete',selected:true},
      {value:'PO_PLACEMENT_PENDING',display:'PO Placement Pending ',selected:true},
      {value:'FULFILLMENT_IN_PROGRESS',display:'Fulfillment In-Progress',selected:true},
      {value:'SHIPPED',display:'Shipped',selected:true},
      {value:'PARTIALLY_SHIPPED',display:'Partially Shipped',selected:true},
      {value:'INVOICED',display:'Invoiced',selected:true},
      {value:'PARTIALLY_INVOICED',display:'Partially Invoiced',selected:true},
      {value:'FILE_CLOSED',display:'File Closed',selected:false},
      {value:'CANCELLED',display:'Cancelled',selected:false},
      ]

      defaultSizeAndQty=[
         {size:'SM',qty:100},
         {size:'MM',qty:200},
         {size:'LG',qty:200},
         {size:'XL',qty:100}];
  
      


   constructor(private toastrService: ToastrService, private metadataService: MetadataService, private sharedService:SharedService, private salesService:SalesService, private brandService: BrandService) {
      this.initializeMetadataSubscription();
   }

   initializeMetadataSubscription() {

      //this.blanksColourListFromMetadataSubject = new BehaviorSubject<string[]>([]);
      //this.blanksColourListFromMetadata = this.blanksColourListFromMetadataSubject.asObservable();

      this.blanksQualityListFromMetadataSubject = new BehaviorSubject<string[]>([]);
      this.blanksQualityListFromMetadata = this.blanksQualityListFromMetadataSubject.asObservable();

      this.packagingTypeListFromMetadataSubject = new BehaviorSubject<string[]>([]);
      this.packagingTypeListFromMetadata = this.packagingTypeListFromMetadataSubject.asObservable();

      this.customerPoTypeListFromMetadataSubject = new BehaviorSubject<string[]>([]);
      this.customerPoTypeListFromMetadata = this.customerPoTypeListFromMetadataSubject.asObservable();

      this.trimsListFromMetadataSubject = new BehaviorSubject<string[]>([]);
      this.trimsListFromMetadata = this.trimsListFromMetadataSubject.asObservable();

      //this.sizesListFromMetadataSubject = new BehaviorSubject<string[]>([]);
      //this.sizesListFromMetadata = this.sizesListFromMetadataSubject.asObservable();

      
      this.cooListFromMetadataSubject = new BehaviorSubject<string[]>([]);
      this.cooListFromMetadata = this.cooListFromMetadataSubject.asObservable();

      this.printerJobTypeFromMetadataSubject = new BehaviorSubject<string[]>([]);
      this.printerJobTypeFromMetadata = this.printerJobTypeFromMetadataSubject.asObservable();

      this.skuStatusesNotAllowedForPreProRequiredFromMetadataSubject = new BehaviorSubject<string[]>([]);
      this.skuStatusesNotAllowedForPreProRequiredFromMetadata = this.skuStatusesNotAllowedForPreProRequiredFromMetadataSubject.asObservable();

   }

   loadOrderDetailMetadata() {
      
      this.loadMetadata('SALES', 'BLANKS_QUALITY', this.blanksQualityListFromMetadataSubject);
      this.loadMetadata('SALES', 'PACKAGING_TYPE', this.packagingTypeListFromMetadataSubject);
      this.loadMetadata('SALES', 'CUSTOMER_PO_TYPE', this.customerPoTypeListFromMetadataSubject);
      this.loadMetadata('SALES', 'TRIMS', this.trimsListFromMetadataSubject);
      this.loadMetadata('SALES', 'COO', this.cooListFromMetadataSubject);
      this.loadMetadata('SALES', 'PRINTER_JOB_TYPE', this.printerJobTypeFromMetadataSubject);
      this.loadMetadataWithTypeAndSubType('SALES', 'PRE_PRO_REQUIRED', 'SKU_STATUS_NOT_ALLOWED', this.skuStatusesNotAllowedForPreProRequiredFromMetadataSubject);
   }

   private loadMetadata(classification: string, type: string, behaviourSubject: BehaviorSubject<string[]>) {
      this.metadataService.getMetadataValueListByClassificationAndType(classification, type).subscribe(response => {
         behaviourSubject.next(response.responsePayload);
      }, err => {
         console.error('Error while loading Trmis List from Metadata : ' + JSON.stringify(err));
      });
   }

   private loadMetadataWithTypeAndSubType(classification: string, type: string, subtype: string, behaviourSubject: BehaviorSubject<string[]>) {
      this.metadataService.getMetadataValueListByClassificationAndTypeAndSubType(classification, type, subtype).subscribe(response => {
         behaviourSubject.next(response.responsePayload);
      }, err => {
         console.error('Error while loading Metadata with Type and SubType : ' + JSON.stringify(err));
      });
   }

   createNewSubOrderDetailForSales(salesOrder: SalesOrder, orderDetail: OrderDetail, packagingTypeListFromMetadata: string[]): SubOrderDetail {
      let newSubOrderDetail = new SubOrderDetail;
      if (salesOrder.singlePO) {
         newSubOrderDetail.customerPONumber = salesOrder.singlePoNumber;
      }
      newSubOrderDetail.category = 'SALES';
      newSubOrderDetail.status = "ACTIVE";
      if (orderDetail !== null && orderDetail !== undefined && orderDetail.id !== null && orderDetail.id !== undefined) {
         newSubOrderDetail.parentId = orderDetail.id;
      }
      newSubOrderDetail.type = 'SALES';
      let maxSortOrder: number = this.getSubOrderDetailMaxSortOrder(orderDetail);
      newSubOrderDetail.sortOrder = maxSortOrder + 1;
      let sizeOrderDetailList: SizeOrderDetail[] = [];
      sizeOrderDetailList = this.createNewSizeOrderDetailList(salesOrder,orderDetail, newSubOrderDetail);
      newSubOrderDetail.sizeOrderDetailList = sizeOrderDetailList;
      this.selectDefaultPackagingType(newSubOrderDetail, packagingTypeListFromMetadata);
      return newSubOrderDetail;
   }

   createNewSizeOrderDetailList(salesOrder: SalesOrder,orderDetail: OrderDetail, subOrderDetail: SubOrderDetail): SizeOrderDetail[] {
      let newSizeOrderDetailList: SizeOrderDetail[] = [];
      for (let index = 0; index < orderDetail?.orderDetailSizeQuantityList.length; index++) {
         let orderDetailSizeQuantity: OrderDetailSizeQuantity = orderDetail.orderDetailSizeQuantityList[index];
         let newSizeOrderDetail = this.createNewSizeOrderDetail(salesOrder,orderDetail, orderDetailSizeQuantity, subOrderDetail);
         newSizeOrderDetailList.push(newSizeOrderDetail);
      }
      return newSizeOrderDetailList;
   }

   createNewSizeOrderDetail(salesOrder: SalesOrder,orderDetail: OrderDetail, orderDetailSizeQuantity: OrderDetailSizeQuantity, subOrderDetail: SubOrderDetail): SizeOrderDetail {
      let newSizeOrderDetail = new SizeOrderDetail;
      newSizeOrderDetail.size = orderDetailSizeQuantity.size;
      newSizeOrderDetail.overrideSize = orderDetailSizeQuantity.overrideSize;
      newSizeOrderDetail.sizeOverrideFlag = orderDetail.overrideSizes;
      if ('SAMPLE' === subOrderDetail.category) {
         newSizeOrderDetail.quantity = 0;
      }
      if(salesOrder.orderType === 'Estimation' && 'SAMPLE' !== subOrderDetail.category){
         let defult=this.defaultSizeAndQty.filter(x=>x.size===newSizeOrderDetail.size);
         if(defult!=null && defult.length>0){
            newSizeOrderDetail.quantity = defult[0].qty;
         }else{
            newSizeOrderDetail.quantity = 200;
         }
      }
      return newSizeOrderDetail;
   }

   getSubOrderDetailMaxSortOrder(orderDetail: OrderDetail): number {
      let maxSortOrder: number = 0;
      for (let index = 0; index < orderDetail.subOrderDetailList.length; index++) {
         let subOrderDetail = orderDetail.subOrderDetailList[index];
         if (subOrderDetail.type === 'SALES') {
            let subOrderDetailSortOrder: number = subOrderDetail.sortOrder;

            if (maxSortOrder < subOrderDetailSortOrder) {
               maxSortOrder = subOrderDetailSortOrder;
            }
         }
      }
      return maxSortOrder;
   }

   private selectDefaultPackagingType(newSubOrderDetail: SubOrderDetail, packagingTypeListFromMetadata: string[]) {
      if (packagingTypeListFromMetadata && packagingTypeListFromMetadata[0]) {
         newSubOrderDetail.packagingType = packagingTypeListFromMetadata[0];
      }
   }

   createNewSubOrderDetailForPPSample(salesOrder: SalesOrder,orderDetail: OrderDetail, packagingTypeListFromMetadata: string[]): SubOrderDetail {
      let newSubOrderDetail = new SubOrderDetail;
      newSubOrderDetail.category = 'SAMPLE';
      newSubOrderDetail.status = "ACTIVE";
      if (orderDetail !== null && orderDetail !== undefined && orderDetail.id !== null && orderDetail.id !== undefined) {
         newSubOrderDetail.parentId = orderDetail.id;
      }
      newSubOrderDetail.type = 'PP_SAMPLE';
      newSubOrderDetail.sortOrder = 998;
      let sizeOrderDetailList: SizeOrderDetail[] = [];
      sizeOrderDetailList = this.createNewSizeOrderDetailList(salesOrder,orderDetail, newSubOrderDetail);
      newSubOrderDetail.sizeOrderDetailList = sizeOrderDetailList;
      this.selectDefaultPackagingType(newSubOrderDetail, packagingTypeListFromMetadata);
      return newSubOrderDetail;
   }

   createNewSubOrderDetailForTopSample(salesOrder: SalesOrder,orderDetail: OrderDetail, packagingTypeListFromMetadata: string[]): SubOrderDetail {
      let newSubOrderDetail = new SubOrderDetail;
      newSubOrderDetail.category = 'SAMPLE';
      newSubOrderDetail.status = "ACTIVE";
      if (orderDetail !== null && orderDetail !== undefined && orderDetail.id !== null && orderDetail.id !== undefined) {
         newSubOrderDetail.parentId = orderDetail.id;
      }
      newSubOrderDetail.type = 'TOP_SAMPLE';
      newSubOrderDetail.sortOrder = 999;
      let sizeOrderDetailList: SizeOrderDetail[] = [];
      sizeOrderDetailList = this.createNewSizeOrderDetailList(salesOrder,orderDetail, newSubOrderDetail);
      newSubOrderDetail.sizeOrderDetailList = sizeOrderDetailList;
      this.selectDefaultPackagingType(newSubOrderDetail, packagingTypeListFromMetadata);
      return newSubOrderDetail;
   }

   createNewSubOrderDetailForLicensorSample(salesOrder: SalesOrder,orderDetail: OrderDetail, packagingTypeListFromMetadata: string[]): SubOrderDetail {
      let newSubOrderDetail = new SubOrderDetail;
      newSubOrderDetail.category = 'SAMPLE';
      newSubOrderDetail.status = "ACTIVE";
      if (orderDetail !== null && orderDetail !== undefined && orderDetail.id !== null && orderDetail.id !== undefined) {
         newSubOrderDetail.parentId = orderDetail.id;
      }
      newSubOrderDetail.type = 'LICENSOR_SAMPLE';
      newSubOrderDetail.sortOrder = 1000;
      let sizeOrderDetailList: SizeOrderDetail[] = [];
      sizeOrderDetailList = this.createNewSizeOrderDetailList(salesOrder,orderDetail, newSubOrderDetail);
      newSubOrderDetail.sizeOrderDetailList = sizeOrderDetailList;
      this.selectDefaultPackagingType(newSubOrderDetail, packagingTypeListFromMetadata);
      return newSubOrderDetail;
   }

   /***************************** START: UPDATE STATUS AND PROPOGATE STATUS  **************************/

   updateAndPropogateSalesOrderStatus(salesOrder: SalesOrder, salesOrderStatus: string, orderDetailStatus: string, subOrderDetailStatus: string) {
      salesOrder.status = salesOrderStatus;
      for (let i = 0; i < salesOrder.orderDetails?.length; i++) {
         let orderDetail: OrderDetail = salesOrder.orderDetails[i];
         this.updateAndPropogateOrderDetailStatus(orderDetail, orderDetailStatus, subOrderDetailStatus);
      }
   }

   updateAndPropogateOrderDetailStatus(orderDetail: OrderDetail, orderDetailStatus: string, subOrderDetailStatus: string) {
      orderDetail.status = orderDetailStatus;
      for (let j = 0; j < orderDetail?.subOrderDetailList?.length; j++) {
         let subOrderDetail: SubOrderDetail = orderDetail.subOrderDetailList[j];
         this.updateAndPropogateSubOrderDetailStatus(subOrderDetail, subOrderDetailStatus)
      }
   }

   updateAndPropogateSubOrderDetailStatus(subOrderDetail: SubOrderDetail, subOrderDetailStatus: string) {
      subOrderDetail.status = subOrderDetailStatus;
   }

   /***************************** END: UPDATE STATUS AND PROPOGATE STATUS  **************************/

   prepareSizeListForModal(orderDetail: OrderDetail, sizesMetadataList: MetadataSize[]): OrderDetailSizeQuantity[] {
      
      let ordeDetailSizeQuantityListForModal = [];
      for (let index = 0; index < sizesMetadataList.length; index++) {
         let sizeFromMetadata = sizesMetadataList[index].size;
         /*let orderDetailSizeQuantity: OrderDetailSizeQuantity = new OrderDetailSizeQuantity;
         orderDetailSizeQuantity.orderId = orderDetail.id;
         orderDetailSizeQuantity.size = sizeFromMetadata;
         orderDetailSizeQuantity.isSelected = this.doesSizeExistsForAnOrderDetail(sizeFromMetadata);
         */
         let orderDetailSizeQuantity: OrderDetailSizeQuantity = this.getOrderDetailSizeQuantityFromCurrentOrderDetail(orderDetail, sizeFromMetadata,sizesMetadataList[index].status);
         
         ordeDetailSizeQuantityListForModal.push(orderDetailSizeQuantity);

      }
      return ordeDetailSizeQuantityListForModal;
   }

   getOrderDetailSizeQuantityFromCurrentOrderDetail(orderDetail: OrderDetail, sizeFromModal: string,status:boolean): OrderDetailSizeQuantity {
      let currentOrderDetail = orderDetail;
      let orderDetailSizeQuantity: OrderDetailSizeQuantity = currentOrderDetail.orderDetailSizeQuantityList.find(list => list.size === sizeFromModal);
      if (orderDetailSizeQuantity === null || orderDetailSizeQuantity === undefined) {
         orderDetailSizeQuantity = new OrderDetailSizeQuantity;
         orderDetailSizeQuantity.parentId = currentOrderDetail.id;
         orderDetailSizeQuantity.size = sizeFromModal;
         orderDetailSizeQuantity.selected = false;
         orderDetailSizeQuantity.activeSize = status;
      } else {
         orderDetailSizeQuantity.selected = true;
         orderDetailSizeQuantity.activeSize = status;
      }
      return orderDetailSizeQuantity;
   }

   /************************** START: RECALCULATIONS FOR QTY & COSTS **************************/

   reCalculateFullSalesOrder(salesOrder: SalesOrder) {
      for (let i = 0; i < salesOrder.orderDetails?.length; i++) {
         let orderDetail: OrderDetail = salesOrder.orderDetails[i];
         //if (!orderDetail.deleteFlag && orderDetail.status !== 'CANCELLED') {
            for (let j = 0; j < orderDetail.subOrderDetailList?.length; j++) {
               let subOrderDetail: SubOrderDetail = orderDetail.subOrderDetailList[j];
               this.reCalculateSubOrderTotalQuantity(salesOrder, orderDetail, subOrderDetail);
            }
         //}
      }
   }

   reCalculateSubOrderTotalQuantity(salesOrder: SalesOrder, orderDetail: OrderDetail, subOrderDetail: SubOrderDetail): number {
      let reCalculatedTotalQuantity: number = 0;
      for (let index = 0; index < subOrderDetail?.sizeOrderDetailList?.length; index++) {
         let sizeOrderDetail: SizeOrderDetail = subOrderDetail?.sizeOrderDetailList[index];
         reCalculatedTotalQuantity += sizeOrderDetail.quantity;
      }
      subOrderDetail.totalSubOrderQuantity = reCalculatedTotalQuantity;
      this.reCalculateOrderDetailSizeAndQuantity(orderDetail);
      this.reCalculateOrderDetailTotalCosts(salesOrder, orderDetail);
      /*if(orderDetail.ppSampleRequired && subOrderDetail.category==='SAMPLE' && subOrderDetail.type==='PP_SAMPLE' && subOrderDetail.totalSubOrderQuantity===0){
         subOrderDetail.totalSubOrderQuantity=undefined;
      }*/
      return reCalculatedTotalQuantity;
   }

   reCalculateOrderDetailTotalCosts(salesOrder: SalesOrder, orderDetail: OrderDetail) {
      // Order Detail Total Cost based on Single Cost or Individual Costs
      if (orderDetail.individualSizeCosts) {
         this.reCalculateOrderDetailTotalCostByIndividualCosts(salesOrder, orderDetail)
      } else {
         this.reCalculateOrderDetailTotalCostBySingleCost(salesOrder, orderDetail);
      }
      // Order Detail Total Cost based on Single Retail Cost
      this.reCalculateOrderDetailTotalRetailCostBySingleCost(salesOrder, orderDetail);
      // Sales Order Total Unit and Cost
      this.reCalculateSalesOrderTotalUnitAndCost(salesOrder);
   }

   reCalculateOrderDetailTotalRetailCostBySingleCost(salesOrder: SalesOrder, orderDetail: OrderDetail) {
      let reCalculatedTotalRetailCost: number = 0;
      let totalOrderQuantity: number = 0;
      let totalSampleQuantity: number = 0;
      orderDetail.subOrderDetailList.forEach((subOrderDetail, index) => {
         if (!subOrderDetail.deleteFlag && subOrderDetail.status !== 'CANCELLED') {
            let totalSubOrderQuantity = subOrderDetail.totalSubOrderQuantity;
            if (subOrderDetail.type === 'SALES') {
               reCalculatedTotalRetailCost += totalSubOrderQuantity * orderDetail.retailCost;
               totalOrderQuantity += subOrderDetail.totalSubOrderQuantity;
            } else {
               totalSampleQuantity += subOrderDetail.totalSubOrderQuantity;
            }
         }
      });

      orderDetail.totalRetailCost = parseFloat(reCalculatedTotalRetailCost.toFixed(2));
      orderDetail.totalOrderQuantity = totalOrderQuantity;
      orderDetail.totalSampleQuantity = totalSampleQuantity;
   }

   reCalculateOrderDetailTotalCostBySingleCost(salesOrder: SalesOrder, orderDetail: OrderDetail) {
      let reCalculatedTotalCost: number = 0;
      let totalOrderQuantity: number = 0;
      let totalSampleQuantity: number = 0;
      orderDetail.subOrderDetailList.forEach((subOrderDetail, index) => {
         if (!subOrderDetail.deleteFlag && subOrderDetail.status !== 'CANCELLED') {
            let totalSubOrderQuantity = subOrderDetail.totalSubOrderQuantity;
            if (subOrderDetail.type === 'SALES') {
               reCalculatedTotalCost += totalSubOrderQuantity * orderDetail.cost;
               totalOrderQuantity += subOrderDetail.totalSubOrderQuantity;
            } else {
               totalSampleQuantity += subOrderDetail.totalSubOrderQuantity;
            }
         }
      });
      orderDetail.totalCost = parseFloat(reCalculatedTotalCost.toFixed(2));
      orderDetail.totalOrderQuantity = totalOrderQuantity;
      orderDetail.totalSampleQuantity = totalSampleQuantity;
   }

   reCalculateOrderDetailTotalCostByIndividualCosts(salesOrder: SalesOrder, orderDetail: OrderDetail) {
      let reCalculatedTotalCost: number = 0;
      let reCalculatedTotalRetailCost: number = 0;
      let totalOrderQuantity: number = 0;
      for (let index = 0; index < orderDetail.orderDetailSizeQuantityList.length; index++) {
         let orderDetailSizeQuantity: OrderDetailSizeQuantity = orderDetail.orderDetailSizeQuantityList[index];
         reCalculatedTotalCost += orderDetailSizeQuantity.quantity * orderDetailSizeQuantity.cost;
      }
      orderDetail.totalCost = reCalculatedTotalCost;
      orderDetail.totalOrderQuantity = totalOrderQuantity;
   }

   reCalculateSalesOrderTotalUnitAndCost(salesOrder: SalesOrder) {
      let reCalculatedTotalUnits: number = 0;
      let reCalculatedTotalCost: number = 0;
      let reCalculatedTotalSamples: number = 0;
      salesOrder.orderDetails.forEach((orderDetail, index) => {
         if (!orderDetail.deleteFlag && orderDetail.status !== 'CANCELLED') {
            if (orderDetail.totalOrderQuantity !== undefined) {
               reCalculatedTotalUnits += orderDetail.totalOrderQuantity;
            }
            if (orderDetail.totalSampleQuantity !== undefined) {
               reCalculatedTotalSamples += orderDetail.totalSampleQuantity;
            }
            reCalculatedTotalCost += orderDetail.totalCost;
         }
      });
      salesOrder.totalUnits = reCalculatedTotalUnits;
      salesOrder.totalCost = reCalculatedTotalCost;
      salesOrder.totalSampleUnits = reCalculatedTotalSamples;
   }

   reCalculateOrderDetailSizeAndQuantity(orderDetail: OrderDetail) {
      let totalOrderQuantityForSize: number = 0;
      let totalSampleQuantityForSize: number = 0;
      for (let index = 0; index < orderDetail.orderDetailSizeQuantityList.length; index++) {
         let orderDetailSizeQuantity: OrderDetailSizeQuantity = orderDetail.orderDetailSizeQuantityList[index];
         totalOrderQuantityForSize = this.calculateTotalQuantityForEachOrderSizeForOrderDetail(orderDetail, orderDetailSizeQuantity.size);
         totalSampleQuantityForSize = this.calculateTotalQuantityForEachSampleSizeForOrderDetail(orderDetail, orderDetailSizeQuantity.size);
         orderDetailSizeQuantity.quantity = totalOrderQuantityForSize;
         orderDetailSizeQuantity.sampleQuantity = totalSampleQuantityForSize;
      }
   }

   calculateTotalQuantityForEachOrderSizeForOrderDetail(orderDetail: OrderDetail, sizeForQuantityCalculation: string): number {
      let totalOrderQuantityForSize: number = 0;
      for (let index = 0; index < orderDetail.subOrderDetailList.length; index++) {
         let subOrderDetail: SubOrderDetail = orderDetail.subOrderDetailList[index];
         if (subOrderDetail.type === 'SALES') {
            if (!subOrderDetail.deleteFlag && subOrderDetail.status !== 'CANCELLED') {
               for (let index = 0; index < subOrderDetail.sizeOrderDetailList.length; index++) {
                  let sizeOrderDetail: SizeOrderDetail = subOrderDetail.sizeOrderDetailList[index];
                  if (sizeOrderDetail.size === sizeForQuantityCalculation) {
                     totalOrderQuantityForSize = totalOrderQuantityForSize + sizeOrderDetail.quantity;
                  }
               }
            }
         }
      }
      return totalOrderQuantityForSize;
   }

   calculateTotalQuantityForEachSampleSizeForOrderDetail(orderDetail: OrderDetail, sizeForQuantityCalculation: string): number {
      let totalSampleQuantityForSize: number = 0;
      for (let index = 0; index < orderDetail.subOrderDetailList.length; index++) {
         let subOrderDetail: SubOrderDetail = orderDetail.subOrderDetailList[index];
         if (subOrderDetail.category === 'SAMPLE') {
            if (!subOrderDetail.deleteFlag && subOrderDetail.status !== 'CANCELLED') {
               for (let index = 0; index < subOrderDetail.sizeOrderDetailList.length; index++) {
                  let sizeOrderDetail: SizeOrderDetail = subOrderDetail.sizeOrderDetailList[index];
                  if (sizeOrderDetail.size === sizeForQuantityCalculation) {
                     totalSampleQuantityForSize = totalSampleQuantityForSize + sizeOrderDetail.quantity;
                  }
               }
            }
         }
      }
      return totalSampleQuantityForSize;
   }

   /****************************** END: RECALCULATIONS FOR QTY & COSTS **************************/

   onSizeSelectionFromModal(salesOrder: SalesOrder, ordeDetailSizeQuantityListForModal: OrderDetailSizeQuantity[], packagingTypeListFromMetadata: string[], currentOrderDetail: OrderDetail, closeModalRequired: boolean) {
      // let currentOrderDetail = this.currentOrderDetail;


      if (this.validateSizeSelection(ordeDetailSizeQuantityListForModal, closeModalRequired)) {
         this.updateSizeQuantityForOrderDetail(currentOrderDetail, ordeDetailSizeQuantityListForModal);
         this.createOrUpdateSizeOrderDetails(salesOrder, currentOrderDetail, ordeDetailSizeQuantityListForModal, packagingTypeListFromMetadata);
         this.reCalculateFullSalesOrder(salesOrder);
      }
   }


   validateSizeSelection(ordeDetailSizeQuantityListForModal: OrderDetailSizeQuantity[], closeModalRequired: boolean) {
      let sizeSelectedCount: number = 0;
      for (let index = 0; index < ordeDetailSizeQuantityListForModal?.length; index++) {
         let orderDetailSizeQuantity: OrderDetailSizeQuantity = ordeDetailSizeQuantityListForModal[index];
         if (orderDetailSizeQuantity.selected) {
            sizeSelectedCount++;
         }
      }
      if (sizeSelectedCount === 0) {
         this.toastrService.error('Please Select atleast one size.');
         return false;
      } else {
         if (closeModalRequired) {
            document.getElementById('sizeSelectionModalToggler').click();
         }
         return true;
      }
   }

   createOrUpdateSizeOrderDetails(salesOrder: SalesOrder, orderDetail: OrderDetail, ordeDetailSizeQuantityListForModal: OrderDetailSizeQuantity[], packagingTypeListFromMetadata: string[]) {
      let subOrderDetailList = orderDetail?.subOrderDetailList;
      if (subOrderDetailList === null || subOrderDetailList === undefined || subOrderDetailList.length === 0) {

         let newSubOrderDetail: SubOrderDetail = this.createNewSubOrderDetailForSales(salesOrder, orderDetail, packagingTypeListFromMetadata);
         let newSizeOrderDetailList: SizeOrderDetail[] = this.getNewSizeOrderDetailListBasedOnSizeSelection(ordeDetailSizeQuantityListForModal, newSubOrderDetail);
         newSubOrderDetail.sizeOrderDetailList = newSizeOrderDetailList;
      } else {

         for (let index = 0; index < orderDetail?.subOrderDetailList?.length; index++) {
            let subOrderDetail: SubOrderDetail = orderDetail.subOrderDetailList[index];
            let newSizeOrderDetailList: SizeOrderDetail[] = this.getNewSizeOrderDetailListBasedOnSizeSelection(ordeDetailSizeQuantityListForModal, subOrderDetail);
            subOrderDetail.sizeOrderDetailList = [];
            subOrderDetail.sizeOrderDetailList = newSizeOrderDetailList;
         }
      }
   }

   getNewSizeOrderDetailListBasedOnSizeSelection(ordeDetailSizeQuantityListForModal: OrderDetailSizeQuantity[], subOrderDetail: SubOrderDetail): SizeOrderDetail[] {
      let newSizeOrderDetailList: SizeOrderDetail[] = [];
      for (let index = 0; index < ordeDetailSizeQuantityListForModal.length; index++) {
         let sizeDetailSelectedFromModal = ordeDetailSizeQuantityListForModal[index];
         if (sizeDetailSelectedFromModal.selected) {
            let existingSizeOrderDetail = this.getExistingSizeOrderDetailFromSubOrderDetail(subOrderDetail.sizeOrderDetailList, sizeDetailSelectedFromModal.size);
            if (existingSizeOrderDetail !== null && existingSizeOrderDetail !== undefined) {
               newSizeOrderDetailList.push(existingSizeOrderDetail);
            } else {
               let newSizeOrderDetail: SizeOrderDetail = new SizeOrderDetail;
               newSizeOrderDetail.parentId = subOrderDetail.id;
               newSizeOrderDetail.size = sizeDetailSelectedFromModal.size;
               if (subOrderDetail.category !== 'SALES') {
                  newSizeOrderDetail.quantity = 0;
               }
               newSizeOrderDetailList.push(newSizeOrderDetail);
            }
         }
      }
      return newSizeOrderDetailList;
   }

   getExistingSizeOrderDetailFromSubOrderDetail(existingSizeOrderDetailList: SizeOrderDetail[], sizeSelectedFromModal: string) {
      for (let index = 0; index < existingSizeOrderDetailList?.length; index++) {
         let tempSizeOrderDetail = existingSizeOrderDetailList[index];
         if (tempSizeOrderDetail.size === sizeSelectedFromModal) {
            let clonedSizeOrderDetail: SizeOrderDetail = new SizeOrderDetail;
            clonedSizeOrderDetail = cloneDeep(tempSizeOrderDetail);
            return clonedSizeOrderDetail;
         }
      }
   }


   updateSizeQuantityForOrderDetail(orderDetail: OrderDetail, ordeDetailSizeQuantityListForModal: OrderDetailSizeQuantity[]) {
      orderDetail.orderDetailSizeQuantityList = [];
      for (let index = 0; index < ordeDetailSizeQuantityListForModal?.length; index++) {
         let orderDetailSizeQuantity: OrderDetailSizeQuantity = ordeDetailSizeQuantityListForModal[index];
         if (orderDetailSizeQuantity.selected) {
            orderDetail.orderDetailSizeQuantityList.push(orderDetailSizeQuantity);
         }
      }
   }


   /************************* START: SIZE OVERRIDE CHECKBOX AND DROPDOWN HANDLING **************************/

   onDropdownChangeSizeOverride(orderDetail: OrderDetail, orderDetailSizeQuantity: OrderDetailSizeQuantity) {
      this.propogateSizeOverrideDetail(orderDetail, orderDetailSizeQuantity.size, orderDetailSizeQuantity.overrideSize, true);
   }

   propogateSizeOverrideDetail(orderDetail: OrderDetail, originalSize: string, overrideSize: string, sizeOverrideFlag: boolean) {
      // Propogate size override change at the order detail level.
      for (let i = 0; i < orderDetail?.orderDetailSizeQuantityList.length; i++) {
         let orderDetailSizeQuantity: OrderDetailSizeQuantity = orderDetail?.orderDetailSizeQuantityList[i];
         if (sizeOverrideFlag) {
            if (orderDetailSizeQuantity.size === originalSize) {
               orderDetailSizeQuantity.overrideSize = overrideSize;
            }
         } else {
            orderDetailSizeQuantity.overrideSize = null;
         }
      }
      // Propogate size override change at the sub order size order detail level.
      for (let i = 0; i < orderDetail?.subOrderDetailList.length; i++) {
         let subOrderDetail: SubOrderDetail = orderDetail?.subOrderDetailList[i];
         for (let j = 0; j < subOrderDetail?.sizeOrderDetailList.length; j++) {
            let sizeOrderDetail: SizeOrderDetail = subOrderDetail?.sizeOrderDetailList[j];
            if (sizeOverrideFlag) {
               if (sizeOrderDetail.size === originalSize) {
                  sizeOrderDetail.overrideSize = overrideSize;
                  sizeOrderDetail.sizeOverrideFlag = sizeOverrideFlag;
               }
            } else {
               sizeOrderDetail.overrideSize = null;
               sizeOrderDetail.sizeOverrideFlag = false;
            }
         }
      }
   }

   totalOrderDetailDeletedCount(salesOrder: SalesOrder): number {
      let orderDeletedCount: number = 0;
      salesOrder.orderDetails?.forEach((orderDetail, index) => {
         if (orderDetail.deleteFlag) {
            orderDeletedCount = orderDeletedCount + 1;
         }
      });
      return orderDeletedCount;
   }

   getTotalSubOrderSampleQtyForOrderDetail(orderDetail:OrderDetail,type:string):number{
      let retVal=0;
      orderDetail.subOrderDetailList.forEach(subOrderDetail => {
         if(subOrderDetail.category==='SAMPLE' && subOrderDetail.type===type){
            retVal=subOrderDetail.totalSubOrderQuantity;
         }
      });
      return retVal;
   }

   createBlankSummariesViews(blankSummaries: BlankSummary[]): BlankSummaryView[] {
      let retVal:BlankSummaryView[]=[];
      if(blankSummaries && blankSummaries.length>0){
         blankSummaries.forEach(blankSummary => {
            let validBlankSummaryView:BlankSummaryView;
            let bsv=retVal.filter(x=>x.submissionCount===blankSummary.submissionCount && x.costingApprovalCount===blankSummary.costingApprovalCount);
            if(bsv && bsv.length>0){
               validBlankSummaryView=bsv[0];
               validBlankSummaryView.blankSummaries.push(blankSummary);
            }else{
               validBlankSummaryView=new BlankSummaryView();
               validBlankSummaryView.blankSummaries.push(blankSummary);
               validBlankSummaryView.createdOn=blankSummary.createdOn;
               validBlankSummaryView.submissionCount=blankSummary.submissionCount;
               validBlankSummaryView.isCurrent=blankSummary.latest;
               validBlankSummaryView.costingApprovalCount=blankSummary.costingApprovalCount;
               validBlankSummaryView.submissionApprovalCount=blankSummary.submissionApprovalCount;
               validBlankSummaryView.approvalReason=blankSummary.approvalReason;
               
               retVal.push(validBlankSummaryView);
            }
         });
      }
      retVal=this.sharedService.sortListByPropertyName(retVal,'costingApprovalCount','DESC');
      return retVal;
   }

   createGroupingsForSummary(printerSummaries: PrinterSummary[]) {
      if(printerSummaries && printerSummaries.length>0){
         printerSummaries.forEach(printerSummary => {
            let printerSummaryDetail=printerSummary.printerSummaryDetail;
            if(printerSummaryDetail){
               let printerSizeDetailsForSummary=printerSummaryDetail.printerSizeDetails;
               if(printerSizeDetailsForSummary && printerSizeDetailsForSummary.length>0){
                  let groupedPrinterSizeDetailsForSummary=this.createGroupingsForStyleInSummary(printerSummaryDetail,printerSizeDetailsForSummary);
                  printerSummaryDetail.printerBlanksDistributionGroupedList=groupedPrinterSizeDetailsForSummary;
               }
            }
         });
      }
    }
   createGroupingsForStyleInSummary(printerSummaryDetail: PrinterSummaryDetail, printerSizeDetailsForSummary: import("../../model/sales/costing/allocation/printer-size-details.model").PrinterSizeDetailsForSummary[]) {
      let retVal:PrinterBlanksDistributionGrouped[]=[];
      if(printerSizeDetailsForSummary && printerSizeDetailsForSummary.length>0){
         let distinctStyles= [...new Set(printerSizeDetailsForSummary.map(x=>x.sku))] ;
         if(distinctStyles && distinctStyles.length>0){
            distinctStyles.forEach(sku => {
               let newGroup:PrinterBlanksDistributionGrouped=new PrinterBlanksDistributionGrouped();
               newGroup.sku=sku;
               newGroup.printerSizeDetails=printerSizeDetailsForSummary.filter(x=>x.sku===sku && !x.sample);
               newGroup.printerSizeDetails.forEach(psd => {
                  let count=0;
                  if(psd.printerBlanksDistributionSizeDetailsList && psd.printerBlanksDistributionSizeDetailsList.length>0){
                     count=psd.printerBlanksDistributionSizeDetailsList.length;
                  }
                  newGroup.distributionCount=newGroup.distributionCount+count;   
               });
               
               retVal.push(newGroup);
            });
         }
      }
      return retVal;
   }

   createPrinterSummariesViews(printerSummaries: PrinterSummary[]): PrinterSummaryView[] {
      let retVal:PrinterSummaryView[]=[];
      let cancelledPoPrinterSummaries:PrinterSummary[]=[];
      if(printerSummaries && printerSummaries.length>0){
         printerSummaries.forEach(printerSummary => {
            let validPrinterSummaryView:PrinterSummaryView;
            let bsv=retVal.filter(x=>x.submissionCount===printerSummary.submissionCount &&  x.costingApprovalCount===printerSummary.costingApprovalCount);
            if(bsv && bsv.length>0){
               validPrinterSummaryView=bsv[0];
               validPrinterSummaryView.printerSummaries.push(printerSummary);
            }else{
               validPrinterSummaryView=new PrinterSummaryView();
               validPrinterSummaryView.printerSummaries.push(printerSummary);
               validPrinterSummaryView.createdOn=printerSummary.createdOn;
               validPrinterSummaryView.submissionCount=printerSummary.submissionCount;
               validPrinterSummaryView.isCurrent=printerSummary.latest;
               validPrinterSummaryView.costingApprovalCount=printerSummary.costingApprovalCount;
               validPrinterSummaryView.submissionApprovalCount=printerSummary.submissionApprovalCount;
               validPrinterSummaryView.approvalReason=printerSummary.approvalReason;
               retVal.push(validPrinterSummaryView);
            }
            //capture cancelled po summaries additionally to include in current Printer Summary
            if(printerSummary.poStatus==='PO_CANCELLATION_REQUIRED'){
               cancelledPoPrinterSummaries.push(printerSummary);
            }
         });
      }
      retVal=this.sharedService.sortListByPropertyName(retVal,'costingApprovalCount','DESC');
      
      //evaluate printer Summaries to be cancelled starts
         if(retVal && retVal.length>0 && cancelledPoPrinterSummaries && cancelledPoPrinterSummaries.length>0){
            cancelledPoPrinterSummaries.forEach(x=>{
               let summaryAlreadyExistsArr= retVal[0].printerSummaries.filter(ps=>ps.printerId===x.printerId);
               // Check if the printer already exists for the lastest Printer Summary
               if(summaryAlreadyExistsArr.length>0){
                  // Do nothing
               }else{
                  retVal[0].printerSummaries.push(...cancelledPoPrinterSummaries);
               }
            })
            
         }
      //evaluate printer Summaries to be Cancelled Ends
      return retVal;
   }

   async saveProductionStatusChange(salesOrder:SalesOrder,newStatus:string,statusReason:string,statusNote:string) {
      salesOrder.productionStatus=newStatus;
      salesOrder.productionStatusNote=statusNote;
      salesOrder.productionStatusReason=statusReason;
      if (salesOrder.productionStatus && salesOrder.productionStatus !== null) {
         await this.salesService.updateProductionStatusChange(salesOrder).toPromise().then(response => {
            if (response.responseStatus.status === 'SUCCESS') {
               let updatedSalesOrderFromDB = response.responsePayload;
               //this.salesOrder=updatedSalesOrderFromDB;
               //this.salesOrder.editProductionStatus = false;
               //this.submittedSalesOrderList[index] = updatedSalesOrder;
               this.toastrService.success(response.responseStatus.message);
            } else {
               this.toastrService.error(response.responseStatus.message);
            }
         }, err => {
            this.toastrService.error('Error while updating Production Status : ' + JSON.stringify(err));
         });
      }
   }

   populateBrandRequiredTrimsIntoOrderDetail(salesOrder: SalesOrder, product: Product, orderDetail: OrderDetail) {
      orderDetail.brandRequiredTrimsList = [];
      orderDetail.brandRequiredTrims=undefined;
      //if (orderDetail.id === null || orderDetail.id === undefined) {
         this.brandService.loadBrand(product.brandId, false, false).subscribe((res) => {
            let brand = res as Brand;
            if (brand !== null) {
               if (brand.trimHangtagRequired) {
                  orderDetail.brandRequiredTrimsList.push(brand.trimHangtagName);
               }
               if (brand.trimHologramRequired) {
                  orderDetail.brandRequiredTrimsList.push(brand.trimHologramName);
               }
               if (orderDetail.brandRequiredTrimsList !== null && orderDetail.brandRequiredTrimsList !== undefined) {
                  orderDetail.brandRequiredTrims = orderDetail.brandRequiredTrimsList.join();
               }
            }
         });
     // } 
      //else {
      //   orderDetail.brandRequiredTrimsList = orderDetail.brandRequiredTrims.split(',');
      //}
   }

}

