export const environment = {
  
  production: true,
  apiEndPoint:'https://www.test.artifactmanagement.com/api',
  wmsApiEndPoint:'https://www.wms.philcos.com/api',
  jwtConfig :{
    whitelistDomains:['www.test.artifactmanagement.com','test.artifactmanagement.com'],
    blacklistedRoutes: ['https://www.test.artifactmanagement.com/login'],
    headerName:'ArtDatabaseAuthorizationHeader',
    skipTokenWhenExpired: true
  },
  loginBrandIcon:'/assets/test.png',
  brandIcon:'/assets/test.png',
  orgTitle: 'Test App',
  orgTitleIcon:'assets/test.png'
}
/*
export const environment = {
  production: true,
  apiEndPoint:'https://www.art.philcos.com/api',
  wmsApiEndPoint:'https://www.wms.philcos.com/api',
  jwtConfig :{
    whitelistDomains:['www.art.philcos.com','art.philcos.com'],
    blacklistedRoutes: ['https://www.art.philcos.com/login'],
    headerName:'ArtDatabaseAuthorizationHeader',
    skipTokenWhenExpired: true
  },
  loginBrandIcon:'/assets/philcos-logo-web.png',
  brandIcon:'/assets/Philcos-Logo_White.png',
  orgTitle: 'Philcos Links',
  orgTitleIcon:'assets/philcos-logo-web.png'
  
};
*/
