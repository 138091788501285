import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DashletDetails } from 'src/app/shared/model/dashoard/dashlet-details.model';

@Component({
  selector: 'app-dynamic-configuration',
  templateUrl: './dynamic-configuration.component.html',
  styleUrls: ['./dynamic-configuration.component.css']
})
export class DynamicConfigurationComponent implements OnInit {

  constructor() { }

  @Input() dashletDetails:DashletDetails;
  @Output() onFilterChange = new EventEmitter<boolean>();
  ngOnInit(): void {
  }

  filterSelectionChange(filter:any,event:any){
    if(filter && filter.filterOptions){
      filter.filterOptions.forEach(element => {
        element.selected=false;
        if(event.value.includes(element.value)){
          element.selected=true;
        }
      });
    }
    console.log(filter.filterOptions);
    this.onFilterChange.next(true);
  }

}
