import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '.././../../../environments/environment';
import { ApiResponse } from '../../model/api-response';
import { SalesOrder } from '../../model/sales/sales-order.model';
import { OrderDetail } from '../../model/sales/order-detail.model';
import { SubOrderDetail } from '../../model/sales/sub-order-detail.model';
import { ChangeContainer } from '../../model/common/change-container.model';
import { BlankSummary } from '../../model/sales/costing/allocation/summary/blank-summary.model';
import { CostingAllocation } from '../../model/sales/costing/allocation/costing-allocation.model';
import { EventRequest } from '../../model/task/event-request.model';
import { PurchaseOrderView } from '../../model/sage/purchase-order-view.model';

@Injectable({
  providedIn: 'root'
})
export class SalesService {
   
   
   constructor(private http: HttpClient) { }

   readonly rooturl = environment.apiEndPoint;

   getDraftSalesOrderList(): Observable<ApiResponse>{
      return this.http.post<any>(this.rooturl + '/sales/list/draft', null);
   }

   getSubmittedSalesOrderList(): Observable<ApiResponse>{
      return this.http.post<any>(this.rooturl + '/sales/list/submitted' , null);
   }

   getSalesOrder(salesOrderId: string): Observable<ApiResponse>{
      return this.http.post<any>(this.rooturl + '/sales/get', salesOrderId);
   }

   saveSalesOrder(salesOrder: SalesOrder){
      return this.http.post<any>(this.rooturl + '/sales/save', salesOrder);
   }

   createSalesOrder(salesOrder: SalesOrder){
      return this.http.post<any>(this.rooturl + '/sales/create', salesOrder);
   }

   updateSalesOrder(salesOrder: SalesOrder){
      return this.http.post<any>(this.rooturl + '/sales/update', salesOrder);
   }

   submitSalesOrder(salesOrder: SalesOrder){
      return this.http.post<any>(this.rooturl + '/sales/submit', salesOrder);
   }

   deleteSalesOrder(salesOrderId: string){
      return this.http.post<any>(this.rooturl + '/sales/delete', salesOrderId);
   }

   deleteOrderDetail(orderDetail: OrderDetail){
      return this.http.post<any>(this.rooturl + '/sales/orderDetail/delete', orderDetail);
   }

   unsubmitSalesOrder(salesOrder: SalesOrder){
      return this.http.post<any>(this.rooturl + '/sales/unsubmit', salesOrder);
   }
   exportExcel(salesOrder: SalesOrder,type:string){
      return this.http.post(this.rooturl + '/sales/export/excel/'+type, salesOrder,{ responseType: 'blob' });
   }

   updateProductionCoordinatorChange(salesOrder: SalesOrder){
      return this.http.post<any>(this.rooturl + '/sales/update/productionCoordinator', salesOrder);
   }
   
   getAssociatedSalesOrdersForProduct(productId:string){
      return this.http.post(this.rooturl + '/sales/list/associatedWithProduct',productId);
   }

   updateProductionStatusChange(salesOrder: SalesOrder){
      return this.http.post<any>(this.rooturl + '/sales/update/productionStatus', salesOrder);
   }
   /*
   cancelOrderDetail(orderDetail: OrderDetail){
      return this.http.post<any>(this.rooturl + '/sales/orderDetail/cancel', orderDetail);
   }

   cancelSubOrderDetail(subOrderDetail: SubOrderDetail){
      return this.http.post<any>(this.rooturl + '/sales/subOrderDetail/cancel', subOrderDetail);
   }
   */

   loadChangeContainerForSubmissionDiff(diffAttachmentId: string) {
      return this.http.get(this.rooturl + '/sales/submissionDiff/changeContainer/'+diffAttachmentId);
   }

   getRatesConversion(type:string):Observable<any>{
      let rootUrl='https://www.bankofcanada.ca/valet/observations/'+type+'/json?recent=1';
      return this.http.get(rootUrl);
   }

   
   getBlankSummary(salesOrderId: string): Observable<ApiResponse>{
      return this.http.post<any>(this.rooturl + '/blankSummary/get', salesOrderId);
   }

   getBlankSummaryPreview(ca: CostingAllocation): Observable<ApiResponse>{
      return this.http.post<any>(this.rooturl + '/blankSummary/preview', ca);
   }

   getPrinterSummary(salesOrderId: string): Observable<ApiResponse>{
      return this.http.post<any>(this.rooturl + '/printerSummary/get', salesOrderId);
   }

   createBlankSummaryExcel(salesOrderId:string, submissionCnt: number, submissionApprovalCnt: number, costingApprovalCnt: number){
      return this.http.post(this.rooturl + '/blankSummary/export/excel/'+salesOrderId+'/'+submissionCnt+'/'+submissionApprovalCnt+'/'+costingApprovalCnt, null,{ responseType: 'blob' });
   }

   createPrinterSummaryExcel(salesOrderId:string, submissionCnt: number, submissionApprovalCnt: number, costingApprovalCnt: number){
      return this.http.post(this.rooturl + '/printerSummary/export/excel/'+salesOrderId+'/'+submissionCnt+'/'+submissionApprovalCnt+'/'+costingApprovalCnt, null,{ responseType: 'blob' });
   }

   getActivitiesList(salesOrderId: string) {
      return this.http.get<ApiResponse>(this.rooturl + '/sales/activity/'+salesOrderId);
   }

   flagSalesOrder(salesOrder: SalesOrder){
      return this.http.post<any>(this.rooturl + '/sales/flag', salesOrder);
   }
   
   getRepeatOrderDetails(customerId: string, productId: string) {
      return this.http.get<ApiResponse>(this.rooturl + '/skuPrinterAssignment/repeatOrderDetails/'+customerId+'/'+productId);
    }
    

   getPrinterPurchaseOrderView(printerSummaryId: string): Observable<ApiResponse>{
      return this.http.post<any>(this.rooturl + '/purchaseOrder/printer/'+printerSummaryId, null);
   }

   createPrinterPurchaseOrder(printerSummaryId: string, purchaseOverView:PurchaseOrderView, companyCode: string): Observable<ApiResponse>{
      return this.http.post<any>(this.rooturl + '/purchaseOrder/create/printer/'+companyCode+'/'+printerSummaryId, purchaseOverView);
   }

   retryPrinterPurchaseOrderPDF(printerSummaryId: string, purchaseOverView:PurchaseOrderView, companyCode: string): Observable<ApiResponse>{
      return this.http.post<any>(this.rooturl + '/purchaseOrder/retry/printer/popdf/'+companyCode+'/'+printerSummaryId, purchaseOverView);
   }

   updatePrinterPurchaseOrder(printerSummaryId: string, purchaseOverView:PurchaseOrderView, companyCode: string): Observable<ApiResponse>{
      return this.http.post<any>(this.rooturl + '/purchaseOrder/update/printer/'+companyCode+'/'+printerSummaryId, purchaseOverView);
   }

   overridePrinterPurchaseOrder(printerSummaryId: string, purchaseOverView:PurchaseOrderView, companyCode: string,poOperationType:string): Observable<ApiResponse>{
      return this.http.post<any>(this.rooturl + '/purchaseOrder/override/printer/'+companyCode+'/'+printerSummaryId+'/'+poOperationType, purchaseOverView);
   }

   
   cancelPrinterPurchaseOrder(printerSummaryId: string, purchaseOverView:PurchaseOrderView, companyCode: string): Observable<ApiResponse>{
      return this.http.post<any>(this.rooturl + '/purchaseOrder/cancel/printer/'+companyCode+'/'+printerSummaryId, purchaseOverView);
   }

   getBlanksSupplierPurchaseOrderView(blanksSummaryId: string): Observable<ApiResponse>{
      return this.http.post<any>(this.rooturl + '/purchaseOrder/blanksSupplier/'+blanksSummaryId, null);
   }

   createBlanksSupplierPurchaseOrder(blanksSummaryId: string, purchaseOverView:PurchaseOrderView, companyCode: string): Observable<ApiResponse>{
      return this.http.post<any>(this.rooturl + '/purchaseOrder/create/blanksSupplier/'+companyCode+'/'+blanksSummaryId, purchaseOverView);
   }

   getPrinterSummaryDiff(id: string) {
      return this.http.post<any>(this.rooturl + '/printerSummary/get/diff', id);
    }

    getPOMetadata(companyId: string): Observable<ApiResponse>{
      return this.http.post<any>(this.rooturl + '/purchaseOrder/poMetadata/'+companyId, null);
   }
    
   
   requestForReCosting(salesOrderId: string, reason: string, notes: string) {
      let req:EventRequest=new EventRequest();
      req.classificationId=salesOrderId;
      req.reason=reason;
      req.notes=notes;
      req.event="COSTING_ALLOCATION_ISSUES_RECOSTING";
      return this.http.post<any>(this.rooturl + '/sales/requestRecosting', req);
   }

   
   validatePreSubmission(salesOrder: SalesOrder): Observable<ApiResponse>{
      return this.http.post<any>(this.rooturl + '/sales/validatePreSubmission', salesOrder);
   }



   /* ***************************** START: SERVICES FOR Sales Order SELECTION ***************************** */

   selectedSalesOrders: SalesOrder[] = [];

   isSalesOrderSelected(itemId: string): boolean {
      if (this.selectedSalesOrders && this.selectedSalesOrders.length > 0) {
         let temp: SalesOrder[] = this.selectedSalesOrders.filter(itemList => itemList.id === itemId);
         if (temp.length == 1) {
            return true;
         } else {
            return false;
         }
      } else {
         return false;
      }
   }

   selectionChanged(salesOrder: SalesOrder) {
      if (!this.isSalesOrderSelected(salesOrder.id)) {
         this.selectedSalesOrders.push(salesOrder);
      } else {
         this.selectedSalesOrders = this.selectedSalesOrders.filter(item => item.id !== salesOrder.id);
      }
   }

   resetSelectedSalesOrders() {
      this.selectedSalesOrders = [];
   }

/* ***************************** END: SERVICES FOR Sales Order SELECTION ***************************** */



}
