<h1 mat-dialog-title>{{data.title}}</h1>
<hr>
<div  style="min-width: 30vw;">
  <ng-container *ngIf="data.modalType==='CATALOG_ATTACHMENT'">
    <p *ngIf="!data.selectionList || data.selectionList?.length===0" class="text-muted">No catalog draft found</p>
    <mat-dialog-content *ngIf="data.selectionList && data.selectionList.length>0">
      <ng-container>
        <table class="table table-sm table-striped">
          <thead class="thead-dark">
            <tr class="f-13">
              <th class=" text-center align-middle" scope="col">Catalog Name</th>
              <th class=" text-center align-middle">Last Updated</th>
              <th class=" text-center align-middle" colspan="2" class="align-middle"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of data.selectionList" class="f-13">
              <td class=" text-center align-middle">{{item.catalogName}}</td>
              <td class="text-center align-middle">{{item.uploadDate | date:'mediumDate'}}</td>
              <td>
                <div class="d-flex justify-content-end">
                  <button class="btn btn-sm btn-success" type="button" style="height: fit-content;"
                    (click)="select(item)" matTooltip="Load draft - {{item.catalogName}}" matTooltipClass="bg-dark"><i
                      class="fas fa-file-import"></i></button>
                  <button class="btn btn-sm btn-danger ml-2" type="button" style="height: fit-content;"
                    (click)="delete(item)" matTooltip="Delete draft - {{item.catalogName}}" matTooltipClass="bg-dark"><i
                      class="fas fa-trash"></i></button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </mat-dialog-content>
    <div style="float:right;margin: 40px 0px 10px 0px;">
      <input class="btn btn-dark" type="button" [ngModel]="data.cancelBtnText" (click)="close()" value="Close">
    </div>
  </ng-container>

  <!--Printer Selection Starts-->
  <ng-container *ngIf="data.modalType==='MULTI_SELECT'">
    <p *ngIf="!data.selectionList || data.selectionList?.length===0" class="text-muted">No data found</p>
    <mat-dialog-content *ngIf="data.selectionList && data.selectionList.length>0">
      <ng-container>
        <input type="search" name="searchItemString" 
               class="form-control f-13 mb-1 text-danger" 
               placeholder="Search.." #searchStr (keyup)="onKeyupModalFilter(searchStr.value)">
               <!--<input class="form-control f-13 mb-2" #modalSearchInput
               (keyup)="onKeyupModalFilter(modalSearchInput.value,selectedFilterConfig)" placeholder="Find..">-->
        <table class="table table-sm table-striped">
          <thead class="thead-dark">
            <tr class="f-13">
              <th class=" text-center align-middle">Name</th>
              
            </tr>
          </thead>
          <tbody>
            <ng-container  *ngFor="let item of data.selectionList;index as index">
            <tr *ngIf="searchStr.value.length===0 || item.name.toUpperCase().includes(searchStr.value.toUpperCase())" class="f-13">
              <td>
                <div class="custom-control custom-checkbox mr-sm-2">
                  <input type="checkbox" class="custom-control-input " name="{{index}}" id="{{index}}"  
                  (change)="selectedItems($event,item,selectedList)"
                    [checked]="isSelected(item,selectedList)">
                  <label class="custom-control-label text-break" for="{{index}}">{{item.name}}</label>
              </div>
              </td>
              <!--<td class=" text-center align-middle">{{item.name}}</td>
              
              <td>
                <div class="d-flex justify-content-end">
                  <button class="btn btn-sm btn-success" type="button" style="height: fit-content;"
                    (click)="select(item)">Select</button>
                  <button class="btn btn-sm btn-danger ml-2" type="button" style="height: fit-content;"
                    (click)="delete(item)">Remove</button>
                </div>
              </td>-->
            </tr>
          </ng-container>
          </tbody>
        </table>
      </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions class="m-2">
      
      
      <div *ngIf="data && data.selectableOptions" class="mt-2">
        <div *ngFor="let selectableOption of data.selectableOptions; index as soIndex">
          <span class="f-11">
            <div class="custom-control custom-checkbox mr-sm-2">
              <input type="checkbox" class="custom-control-input " name="so_{{soIndex}}" id="so_{{soIndex}}" 
              (change)="selectableOption.value=!selectableOption.value"
              [checked]="selectableOption.value" >
              <label class="custom-control-label text-danger" for="so_{{soIndex}}">{{selectableOption.desc}}</label>
          </div>
          </span>
        </div>
      </div>
      <div>
        <input class="btn btn-success" type="button" [ngModel]="data.confirmBtnText" (click)="confirmMultiSelection()" >
        <input class="btn btn-dark ml-2" type="button" [ngModel]="data.cancelBtnText" (click)="close()" value="Close">
      </div>
    </mat-dialog-actions>
  </ng-container>
  <!--Printer Selection Ends-->

<!--Single Select Starts-->
  <ng-container *ngIf="data.modalType==='SINGLE_SELECT'">
    <p *ngIf="!data.selectionList || data.selectionList?.length===0" class="text-muted">No data found</p>
    <mat-dialog-content *ngIf="data.selectionList && data.selectionList.length>0">
      <ng-container>
        <input type="search" name="searchItemString" 
               class="form-control f-13 mb-1" 
               placeholder="Search.." #searchStr (keyup)="onKeyupModalFilter(searchStr.value)">
               <!--<input class="form-control f-13 mb-2" #modalSearchInput
               (keyup)="onKeyupModalFilter(modalSearchInput.value,selectedFilterConfig)" placeholder="Find..">-->
        <table class="table table-sm table-striped">
          <thead class="thead-dark">
            <tr class="f-13">
              <th class=" text-center align-middle">{{data.displaySelectionTitle}}</th>
              
            </tr>
          </thead>
          <tbody>
            <ng-container  *ngFor="let item of data.selectionList;index as index">
            <tr *ngIf="searchStr.value.length===0 || item.name.toUpperCase().includes(searchStr.value.toUpperCase())" class="f-13">
              <td>
                <div class="custom-control custom-checkbox mr-sm-2">
                  <input type="checkbox" class="custom-control-input " name="{{index}}" id="{{index}}"  
                  (change)="selectedItemsForSingleSelect($event,item,selectedList)"
                    [checked]="isSelected(item,selectedList)">
                  <label class="custom-control-label text-break" for="{{index}}">{{item[data.displayOptionvar]}}</label>
              </div>
              </td>
              <!--<td class=" text-center align-middle">{{item.name}}</td>
              
              <td>
                <div class="d-flex justify-content-end">
                  <button class="btn btn-sm btn-success" type="button" style="height: fit-content;"
                    (click)="select(item)">Select</button>
                  <button class="btn btn-sm btn-danger ml-2" type="button" style="height: fit-content;"
                    (click)="delete(item)">Remove</button>
                </div>
              </td>-->
            </tr>
          </ng-container>
          </tbody>
        </table>
      </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions class="m-2">
      
      
      <div *ngIf="data && data.selectableOptions" class="mt-2">
        <div *ngFor="let selectableOption of data.selectableOptions; index as soIndex">
          <span class="f-11">
            <div class="custom-control custom-checkbox mr-sm-2">
              <input type="checkbox" class="custom-control-input " name="so_{{soIndex}}" id="so_{{soIndex}}" 
              (change)="selectableOption.value=!selectableOption.value"
              [checked]="selectableOption.value" >
              <label class="custom-control-label text-danger" for="so_{{soIndex}}">{{selectableOption.desc}}</label>
          </div>
          </span>
        </div>
      </div>
      <div>
        <input class="btn btn-success" type="button" [ngModel]="data.confirmBtnText" (click)="confirmMultiSelection()" >
        <input class="btn btn-dark ml-2" type="button" [ngModel]="data.cancelBtnText" (click)="close()" value="Close">
      </div>
    </mat-dialog-actions>
  </ng-container>

  <!--Single Select Ends-->





  
</div>