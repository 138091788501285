import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
   selector: 'app-confirm-dialog',
   templateUrl: './confirm-dialog.component.html',
   styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {

   title: string;
   message: string;
   textInput: string;
   allowDiscard: boolean = false;
   numberInput: number;
   dropdownSelection: string;
   submitOnEnter: boolean = false;   
   options: any[];
   footNote:string;
   selectionNote:string;

   constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
      if (!this.data.confirmBtnText) {
         this.data.confirmBtnText = 'Confirm';
      }
      if (!this.data.cancelBtnText) {
         this.data.cancelBtnText = 'Cancel';
      }
      if (!this.data.maxlength) {
         this.data.maxlength = 30;
      }
      if (this.data.textInputDefault) {
         this.textInput = this.data.textInputDefault;
      }
      if (this.data.allowDiscard === true) {
         this.allowDiscard = true;
      }
      if (this.data.submitOnEnter === true) {
         this.submitOnEnter = true;
      }      
      if(this.data.checkBoxGroupOptions){
         this.options=this.data.checkBoxGroupOptions;
      } 
      if(this.data.footNote){
         this.footNote=this.data.footNote;
      }
      if(this.data.selectionNote){
         this.selectionNote=this.data.selectionNote;
      }
      
   }
   ngOnInit(): void {
   }

   save() {
      if (this.data.modalType === 'TEXT_INPUT' || this.data.modalType === 'TEXTAREA_INPUT') {
         this.dialogRef.close(this.textInput);
      } else if (this.data.modalType === 'NUMBER_INPUT') {
         this.dialogRef.close(this.numberInput);
      } else if (this.data.modalType === 'DROPDOWN_AND_TEXTAREA') {
         let dropdownList: any[] = this.data.dropdownList;
         let selectedDataFromDropdown: any = dropdownList.filter(x => x.id === this.dropdownSelection);
         this.dialogRef.close({
            data: {
               dropdownSelection: selectedDataFromDropdown[0],
               detailsText: this.textInput
            }
         });
      }else if (this.data.modalType === 'DROPDOWN_AND_TEXTINPUT') {
         let dropdownList: any[] = this.data.dropdownList;
         let selectedDataFromDropdown: any = dropdownList.filter(x => x.id === this.dropdownSelection);
         this.dialogRef.close({
            data: {
               dropdownSelection: selectedDataFromDropdown[0],
               detailsText: this.textInput
            }
         });
      }else if (this.data.modalType === 'CHECKBOXES') {
         this.dialogRef.close(this.options);
      }
       else {
         this.dialogRef.close(this.textInput);
      }
   }

   close() {
      this.dialogRef.close();
   }

   discardModalWithNoAction() {
      this.dialogRef.close('NO_ACTION');
   }

   isCheckboxConfirmDisabled(){
      console.log('Options',this.options);
      return this.options.some(option => option.mandatory && !option.selected);
   }
}
